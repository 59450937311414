import { SignIn } from '@clerk/remix'
import type { MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => {
  return [
    { title: 'Sign In | Lorikeet' },
    {
      name: 'description',
      content: 'Sign in page for Lorikeet support.',
    },
  ]
}

export default function SignInPage() {
  return (
    <div className="flex h-screen items-center justify-center">
      <SignIn
        appearance={{
          layout: {
            privacyPageUrl: 'https://docs.lorikeetcx.ai/legal/privacy-policy',
          },
          elements: {
            headerTitle: 'font-heading-h1',
            input: '!border border-border',
            button: 'font-label-bold !border border-border',
            footerAction: 'hidden',
            formButtonPrimary:
              'bg-primary !shadow-none hover:bg-primary-hover min-h-8 border-none',
          },
        }}
      />
    </div>
  )
}
